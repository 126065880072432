<template>
  <div>
    <div ref="animation"></div>
    <div
      v-if="!animation"
      :style="{ width: width + 'px', height: height + 'px' }"
    ></div>
  </div>
</template>

<script>
// Import the npm package
import lottie from 'lottie-web'

// Export the SFC
export default {
  // Name of the component
  name: "LottieAnimation",

  // Accept incoming data from parent
  props: {
    file: { type: String, required: true },
    loop: { type: [Boolean, Number], default: false },
    autoPlay: { type: Boolean, default: true },
    renderer: { type: String, default: 'svg' },
    speed: { type: Number, default: 1 },
    height: { type: [Number, String], default: 300 },
    width: { type: [Number, String], default: 300 }
  },

  // Define local data variables
  data() {
    return {
      animation: null
    }
  },

  // Define local method functions
  methods: {
    /**
     * Handle lottie initiation
     *
     * @returns {void}
     */
    init(animationData) {
      let settings = {
        animationData,
        container: this.$refs.animation,
        renderer: this.renderer,
        loop: this.loop,
        autoplay: this.autoPlay,
      }

      this.animation = lottie.loadAnimation(settings)
      this.animation.setSpeed(this.speed)

      this.animation.addEventListener('loopComplete', () => {
        this.$emit('loopComplete', this.animation)
      })
      this.animation.addEventListener('complete', () => {
        this.$emit('complete', this.animation)
      })
      this.animation.addEventListener('enterFrame', () => {
        this.$emit('enterFrame', this.animation)
      })
    },

    /**
     * Control lottie animation play call
     *
     * @returns {void}
     */
    play() {
      if(this.animation) {
        this.animation.play()
      }
    },

    /**
     * Handle lottie animation stop call
     *
     * @returns {void}
     */
    stop() {
      if(this.animation) {
        this.animation.stop()
      }
    },

    /**
     * Handle lottie animation pause call
     */
    pause() {
      if(this.animation) {
        this.animation.pause()
      }
    },
  },

  /**
   * As soon as this component has been rendered
   *
   * @returns {void}
   */
  async mounted() {
    // Get the animation data first
    const response = await fetch("/animations/" + this.file)
    // Get the json from this
    const json = await response.json()

    // Once done, initiate the animation
    this.init(json)
  },

  /**
   * Just before the component is about to be deleted
   *
   * @returns {void}
   */
  beforeDestroy() {
    if (this.animation) {
      this.animation.destroy()
    }
  },
}
</script>
